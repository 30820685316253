import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import { removeLimit, removeToken, removeResetEmail } from "../token";
import { removePermissions } from "../permissions";

function IdleTimerContainer() {
  const idleTimerRef = useRef(null);
  const logout = () => {
    removeToken();
    removePermissions();
    removeLimit();
    removeResetEmail();
    console.log("temps passé");
    window.location.replace("https://citrix-test.payqin.com/#/login");
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={60 * 60 * 1000}
        onIdle={logout}
      ></IdleTimer>
    </div>
  );
}

export default IdleTimerContainer;
